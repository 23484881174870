import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './i18n';

// Import CSS Files
import './Components/styles/variables.css';
import './css/AboutUs.css';
import './css/AddBlog.css';
import './css/AdminSettings.css';
import './css/ApplyJob.css';
import './css/applyJobClone.css';
import './css/BlogDetails.css';
import './css/BusinessApplicants.css';
import './css/BusinessCard.css';
import './css/BusinessSignup.css';
import './css/ConnectOneOnOne.css';
import './css/ContactUs.css';
import './css/createAccount.css';
import './css/CreateJob.css';
import './css/DropDownCard.css';
import './css/Findwork.css';
import './css/Footer.css';
import './css/forgetpassword.css';
import './css/HeroSection.css';
import './css/individual.css';
import './css/InsightsCard.css';
import './css/job.css';
import './css/jobcard.css';
import './css/LabourTrends.css';
import './css/LeadingEdge.css';
import './css/LeftSidebar.css';
import './css/Login.css';
import './css/ManageJobs.css';
import './css/Navbar.css';
import './css/NewIndustries.css';
import './css/SearchJob.css';
import './css/SearchJobCard.css';
import './css/sidebar.css';
import './css/SuperAdminDashboard.css';
import './css/Technologies.css';
import './css/TechnologiesWeSupport.css';
import './css/usersignup.css';
import './css/VisaSponsership.css';
import './css/WhatweOffer.css';
import './css/WorkCard.css';
import './fonts.css';
import './css/Insights.css';
import AdminPrivate from './Components/PrivateRoutes/AdminPrivate';
import UserPrivate from './Components/PrivateRoutes/UserPrivate';
import FullPageSpinner from './ulities/FullPageSpinner';
import { HelmetProvider } from 'react-helmet-async';

// Lazy Loaded Components
const StaffAugmentation = React.lazy(() =>
  import('./Components/StaffAugmentation/StaffAugmentation.jsx')
);
const ApplyJobForm = React.lazy(() =>
  import('./Components/WorkForIndividuals/ApplyJob/ApplyJobForm.jsx')
);
const PartnerWithUs = React.lazy(() =>
  import('./Components/PartnerWithUs/PartnerWithUs.jsx')
);

const ArchivedJobs = React.lazy(() =>
  import('./Components/SuperAdminDashboard/ManageJobs/ArchivedJobs.jsx')
);

const DraftJobs = React.lazy(() =>
  import('./Components/SuperAdminDashboard/ManageJobs/DraftJobs.jsx')
);

const MainForIndividuals = React.lazy(() =>
  import('./Components/Technologies/MainForIndividuals.jsx')
);

const Individual = React.lazy(() =>
  import('./Components/IndividualApplicants/Individual')
);

const LaborTrends = React.lazy(() =>
  import('./Components/InsightsPage/LaborTrends')
);
const LeadingEdge = React.lazy(() =>
  import('./Components/InsightsPage/LeadingEdge/LeadingEdge')
);
const AboutUs = React.lazy(() => import('./Components/AboutUs/AboutUs'));
const ConnectOneOnOne = React.lazy(() =>
  import('./Components/WorkForIndividuals/ConnectOneOnOne/ConnectOneOnOne')
);
const SearchJob = React.lazy(() =>
  import('./Components/WorkForIndividuals/SearchJob/SearchJob')
);
const VisaSponsership = React.lazy(() =>
  import('./Components/WorkForIndividuals/VisaSponsership/VisaSponsership')
);
const SuperAdminDashboard = React.lazy(() =>
  import('./Components/SuperAdminDashboard/SuperAdminDashboard')
);

const Businesses = React.lazy(() =>
  import('./Components/SuperAdminDashboard/Businesses/Businesses')
);
const BusinessUsers = React.lazy(() =>
  import('./Components/SuperAdminDashboard/BusinessUsers/BusinessUsers')
);

const CreateJob = React.lazy(() =>
  import('./Components/SuperAdminDashboard/CreateJob/CreateJob')
);
const Login = React.lazy(() => import('./Components/Login/Login'));
const AdminLogin = React.lazy(() => import('./Components/Login/AdminLogin'));
const CreateAccount = React.lazy(() =>
  import('./Components/Create Account/CreateAccount')
);
const BusinessSignUp = React.lazy(() =>
  import('./Components/BussinessSignUp/BusinessSignUp')
);
const UserSignup = React.lazy(() =>
  import('./Components/UserSignUp/UserSignup')
);
const ForgotPassword = React.lazy(() =>
  import('./Components/ForgotPassword/ForgotPassword')
);
const Layout = React.lazy(() => import('./Components/Layouts/Layout'));
const Jobs = React.lazy(() => import('./Components/IndividualApplicants/Jobs'));
const Settings = React.lazy(() =>
  import('./Components/IndividualApplicants/Settings')
);
const EditProfile = React.lazy(() =>
  import('./Components/IndividualApplicants/EditProfile')
);

const ContactUs = React.lazy(() => import('./Components/ContactUs/ContactUs'));
const ManageJobs = React.lazy(() =>
  import('./Components/SuperAdminDashboard/ManageJobs/ManageJobs')
);

const SingleBusiness = React.lazy(() =>
  import('./Components/SuperAdminDashboard/Businesses/SingleBusiness')
);
const SingleJobs = React.lazy(() =>
  import('./Components/SuperAdminDashboard/ManageJobs/SingleJobs')
);

const NewsLetters = React.lazy(() =>
  import('./Components/SuperAdminDashboard/NewsLetters/NewsLetters')
);
const Blogs = React.lazy(() => import('./Pages/Blogs/Blogs'));
const BlogDetails = React.lazy(() => import('./Pages/Blogs/BlogDetails'));
const AddBlog = React.lazy(() =>
  import('./Components/SuperAdminDashboard/AddBlog/AddBlog')
);
const EditBlog = React.lazy(() =>
  import('./Components/SuperAdminDashboard/AddBlog/EditBlog')
);
const NotFound = React.lazy(() => import('./Components/NotFound/NotFound'));
const PrivacyPolicy = React.lazy(() => import('./Pages/PrivacyPolicy'));
const TermsAndConidtions = React.lazy(() =>
  import('./Pages/TermsAndConidtions')
);
const ViewJobs = React.lazy(() => import('./Pages/Jobs/ViewJobs'));
const EditJob = React.lazy(() =>
  import('./Components/SuperAdminDashboard/EditJob/EditJob')
);

const Home = lazy(() => import('./Pages/Home/Home'));
const Technologies = lazy(() =>
  import('./Components/Technologies/Technologies')
);

function App() {
  const helmetContext = {};
  return (
    <HelmetProvider context={helmetContext}>
      <BrowserRouter>
        <ToastContainer style={{ zIndex: 100000 }} pauseOnFocusLoss={false} />
        <Suspense fallback={<FullPageSpinner />}>
          <Routes>
            {/* --------Routes with header and footer-------- */}
            <Route
              path="/"
              element={
                <Layout>
                  <Home />
                </Layout>
              }
            />
            <Route
              path="/view-job/:id"
              element={
                <Layout>
                  <ViewJobs />
                </Layout>
              }
            />
            <Route
              path="/blogs"
              element={
                <Layout>
                  <Blogs />
                </Layout>
              }
            />

            <Route
              path="/blog/detail/:id"
              element={
                <Layout>
                  <BlogDetails />
                </Layout>
              }
            />
            <Route
              path="/contact-us"
              element={
                <Layout>
                  <ContactUs />
                </Layout>
              }
            />

            <Route
              path="/technologies"
              element={
                <Layout>
                  <Technologies />
                </Layout>
              }
            />

            <Route
              path="/Leading-Edge"
              element={
                <Layout>
                  <LeadingEdge />
                </Layout>
              }
            />

            <Route
              path="/laborTrends"
              element={
                <Layout>
                  <LaborTrends />
                </Layout>
              }
            />

            <Route
              path="/about-us"
              element={
                <Layout>
                  <AboutUs />
                </Layout>
              }
            />

            <Route
              path="/connect"
              element={
                <Layout>
                  <ConnectOneOnOne />
                </Layout>
              }
            />

            <Route
              path="/search-job"
              element={
                <Layout>
                  <SearchJob />
                </Layout>
              }
            />

            <Route
              path="/Visa-Sponsorship"
              element={
                <Layout>
                  <VisaSponsership />
                </Layout>
              }
            />

            <Route
              path="/connect"
              element={
                <Layout>
                  <ConnectOneOnOne />
                </Layout>
              }
            />
            <Route
              path="/isa-privacy-policy"
              element={
                <Layout>
                  <PrivacyPolicy />
                </Layout>
              }
            />
            <Route
              path="/isa-terms-and-conditions"
              element={
                <Layout>
                  <TermsAndConidtions />
                </Layout>
              }
            />
            <Route
              path="/Partner-with-us"
              element={
                <Layout>
                  <PartnerWithUs />
                </Layout>
              }
            />
            <Route
              path="/Staff-Augmentation"
              element={
                <Layout>
                  <StaffAugmentation />
                </Layout>
              }
            />
            <Route
              path="/Apply-job/:jobId"
              element={
                <Layout>
                  <ApplyJobForm />
                </Layout>
              }
            />

            {/* --------Routes without header and footer-------- */}
            <Route
              path="/login"
              element={
                <Layout>
                  <Login />
                </Layout>
              }
            />
            <Route
              path="/admin/login"
              element={
                <Layout>
                  <AdminLogin />
                </Layout>
              }
            />

            <Route path="/forgot" element={<ForgotPassword />} />
            <Route path="/createaccount" element={<CreateAccount />} />
            <Route
              path="/businessSignup"
              element={
                <Layout>
                  <BusinessSignUp />
                </Layout>
              }
            />
            <Route
              path="/userSignup"
              element={
                <Layout>
                  <UserSignup />
                </Layout>
              }
            />
            <Route
              path="/For-individuals"
              element={
                <Layout>
                  <MainForIndividuals />
                </Layout>
              }
            />
            <Route path="/createaccount" element={<CreateAccount />} />
            <Route path="/businessSignup" element={<BusinessSignUp />} />

            {/* Super admin dashboard with nested routes */}

            <Route path="/admin/" element={<AdminPrivate />}>
              <Route path="dashboard" element={<SuperAdminDashboard />} />
              <Route path="businesses" element={<Businesses />} />
              <Route path="managejobs" element={<ManageJobs />} />
              <Route path="archived-jobs" element={<ArchivedJobs />} />
              <Route path="draft-jobs" element={<DraftJobs />} />
              <Route path="business-users" element={<BusinessUsers />} />

              <Route path="business/:id" element={<SingleBusiness />} />
              <Route path="business/single-job/:id" element={<SingleJobs />} />
              <Route path="newsLetters" element={<NewsLetters />} />
              <Route path="edit-job/:id" element={<EditJob />} />

              <Route path="addBlog" element={<AddBlog />} />
              <Route path="edit-blog/:id" element={<EditBlog />} />

              <Route path="Create-new-job" element={<CreateJob />} />
            </Route>

            {/* User Routes*/}
            <Route path="/" element={<UserPrivate />}>
              <Route path="individualApplicants" element={<Individual />} />
              <Route path="individuals/jobs" element={<Jobs />} />
              <Route path="individuals/settings" element={<Settings />} />
              <Route
                path="individuals/profile/edit"
                element={<EditProfile />}
              />
              <Route path="/individuals/settings" element={<Settings />} />
            </Route>

            <Route path="/userSignUP" element={<UserSignup />} />
            <Route path="/forgot" element={<ForgotPassword />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </HelmetProvider>
  );
}
export default App;
